@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Nunito:wght@400;700&display=swap');

:root {
  --primary-color: #4CAF50;
  --secondary-color: #3B82F6;
  --background-color: #F3F4F6;
  --text-color: #1F2937;
  --accent-color: #8B5CF6;
  --primary-font: 'Ubuntu', sans-serif;
  --hero-primary-color: #4CAF50;
  --hero-secondary-color: #8BC34A;
  --hero-accent-color: #FFC107;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  background-color: #E8F5E9;
  overflow-x: hidden;
}

body {
  font-family: var(--primary-font);
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  position: relative;
  width: 100%;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
}

header {
  background-color: white;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  padding: 10px 0;
}

nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0;
}

.logo {
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 5px;
}

.nav-links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-links a {
  color: var(--text-color);
  text-decoration: none;
  margin: 5px 0;
  font-weight: 600;
  transition: color 0.3s ease;
  cursor: pointer;
}

.nav-links a:hover {
  color: var(--primary-color);
}

main {
  padding-top: 60px;
}

.hero {
  background: linear-gradient(135deg, var(--hero-primary-color), var(--hero-secondary-color));
  color: white;
  text-align: center;
  padding: 20px;
  margin-top: 60px;
  margin-bottom: 60px;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  min-height: calc(100vh - 60px);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding-top: 120px;
}

.hero h1 {
  font-family: 'Fredoka One', cursive;
  font-size: 4rem;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  animation: titleAnimation 1s ease-out;
  position: relative;
  z-index: 1;
  padding: auto;
}

.hero p {
  font-size: 1.6rem;
  max-width: 700px;
  margin: 0 auto 30px;
  line-height: 1.6;
  animation: fadeIn 1.5s ease-out;
  position: relative;
  z-index: 10;
}


.cta-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.cta-button {
  background-color: var(--hero-accent-color);
  color: var(--text-color);
  border: none;
  padding: 12px 24px;
  font-size: 1.3rem;
  border-radius: 50px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  position: relative;
  z-index: 10;
  transition: all 0.3s ease;
  overflow: visible;
}

@media (max-width: 768px) {
  .hero {
    margin-top: 120px; /* Increased margin-top for smaller screens */
    min-height: calc(100vh - 120px);
    padding-top: 60px;
  }
  .cta-button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
  .hero h1 {
    font-size: 2.5rem; /* Reduce font size for smaller screens */
  }

  .hero p {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .cta-button {
    font-size: 0.9rem;
    max-width: 80%;
    padding: 10px 20px;
  }
  .hero {
    margin-top: 150px; /* Further increased margin-top for very small screens */
    min-height: calc(100vh - 150px);
    padding-top: 40px;
  }

  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1rem;
  }
}

@media (max-width: 320px) {
  .cta-button {
    padding: 0.6rem 1rem;
    font-size: 0.8rem;
  }
}


.cta-button:before {
  content: none;
}

.cta-button:hover:before {
  left: 100%;
}

.cta-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--hero-accent-color);
  z-index: -1;
  border-radius: inherit;
}

.cta-button:hover {
  background-color: #FFA000;
  color: #FFFFFF;
  transform: scale(1.05) rotate(2deg);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.floating {
  position: absolute;
  animation: float 6s ease-in-out infinite;
}

.tree {
  width: 120px;
  height: 180px;
  position: absolute;
}

.tree-trunk {
  width: 24px;
  height: 100px;
  background-color: #8B4513;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.tree-top {
  width: 0;
  height: 0;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-bottom: 120px solid #228B22;
  margin-top: -40px;
  position: relative;
  z-index: 2;
  animation: sway 4s ease-in-out infinite;
}

.tree1 { bottom: 10%; right: 10%; animation-delay: -3s; }
.tree2 { bottom: 5%; left: 5%; animation-delay: -5s; }

.world {
  width: 180px;
  height: 180px;
  background-color: #2196F3;
  border-radius: 50%;
  position: absolute;
  bottom: 20%;
  right: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation: rotate 30s linear infinite, float 6s ease-in-out infinite;
  box-shadow: 0 0 20px rgba(255,255,255,0.3);
}

.continent {
  position: absolute;
  background-color: #4CAF50;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.continent1 { width: 70px; height: 50px; top: 30px; left: 20px; border-radius: 50%; }
.continent2 { width: 90px; height: 60px; bottom: 20px; right: 10px; border-radius: 40%; }

.clouds {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 5;
}

.cloud {
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  animation: cloudFloat linear infinite;
  opacity: 0.4;
  transition: opacity 0.5s ease;
}

.cloud1 {
  width: 300px;
  height: 150px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 150"><path fill="%23FFF" d="M30,105 Q45,70 75,90 Q90,50 120,75 Q150,40 180,75 Q210,50 240,75 Q270,50 285,90 Q300,75 300,105 Q285,120 255,120 Q240,135 210,120 Q180,140 150,120 Q120,135 90,120 Q60,135 30,105 Z"/></svg>');
  top: 10%;
  left: -300px;
  animation-duration: 60s;
}

.cloud2 {
  width: 250px;
  height: 125px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 125"><path fill="%23FFF" d="M25,87.5 Q37.5,58.3 62.5,75 Q75,41.7 100,62.5 Q125,33.3 150,62.5 Q175,41.7 200,62.5 Q225,41.7 237.5,75 Q250,62.5 250,87.5 Q237.5,100 212.5,100 Q200,112.5 175,100 Q150,116.7 125,100 Q100,112.5 75,100 Q50,112.5 25,87.5 Z"/></svg>');
  top: 30%;
  left: -250px;
  animation-duration: 45s;
  animation-delay: -15s;
}

.cloud3 {
  width: 200px;
  height: 100px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 100"><path fill="%23FFF" d="M20,70 Q30,46.7 50,60 Q60,33.3 80,50 Q100,26.7 120,50 Q140,33.3 160,50 Q180,33.3 190,60 Q200,50 200,70 Q190,80 170,80 Q160,90 140,80 Q120,93.3 100,80 Q80,90 60,80 Q40,90 20,70 Z"/></svg>');
  top: 50%;
  left: -200px;
  animation-duration: 50s;
  animation-delay: -30s;
}

.sun {
  position: absolute;
  width: 100px;
  height: 100px;
  background: #FFD700;
  border-radius: 50%;
  box-shadow: 0 0 20px #FFD700;
  top: 10%;
  left: 10%;
  animation: sunPulse 4s ease-in-out infinite;
}

.recycle {
  position: absolute;
  width: 80px;
  height: 80px;
  bottom: 15%;
  left: 15%;
  animation: rotate 10s linear infinite;
}

@keyframes titleAnimation {
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes sway {
  0%, 100% { transform: rotate(0deg); }
  50% { transform: rotate(5deg); }
}

@keyframes cloudFloat {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100vw); }
}

@keyframes sunPulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
}

@media (max-width: 768px) {
  .hero {
    padding-top: 200px;
  }
  
  .hero h1 {
    font-size: 3rem;
  }
  
  .hero p {
    font-size: 1.2rem;
  }
}

.cta-button:hover, .cta-button:focus {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  background-color: var(--secondary-color);
  color: white;
}

.cta-button:active {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.section {
  padding: 60px 0;
}

.section-title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 60px;
  color: var(--primary-color);
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.card {
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-content {
  padding: 20px;
}

.card-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.card-description {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 15px;
}

.card-link {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 600;
}

.book-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
}

.book-card {
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.book-card:hover {
  transform: translateY(-5px);
}

.book-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.book-content {
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.book-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.book-description {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 15px;
}

.book-link {
  color: var(--secondary-color);
  text-decoration: none;
  font-weight: 600;
  align-self: flex-start;
}

.playcards {
  background-color: var(--background-color);
  padding: 30px;
  border-radius: 10px;
  margin-top: 50px;
  text-align: center;
}

.playcards-title {
  font-size: 1.8rem;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.playcards-description {
  font-size: 1rem;
  color: var(--text-color);
  margin-bottom: 20px;
  max-width: 800px;
  margin: 0 auto 20px;
}

.playcards-cta {
  display: inline-block;
  background-color: var(--primary-color);
  color: white;
  padding: 12px 24px;
  border-radius: 10px;
  text-decoration: none;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.playcards-cta:hover {
  background-color: var(--secondary-color);
  transform: translateY(-3px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}


.playcards-modal .modal-content {
  max-width: 800px;
  padding-top: 20px; 
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);

}

.playcards-images {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  margin-bottom: 20px;
  padding: 20px 0; /* Add vertical padding */
  justify-content: center;
  margin: 20px 0;
}

.playcards-images img {
  height: auto;
  object-fit: contain;
  max-height: 400px;
  /* transition: transform 0.3s ease; */
  border-radius: 10px;
  /* box-shadow: 0 10px 20px rgba(0,0,0,0.1); */
  transition: all 0.3s ease;
  max-width: 30%;
}

@media (max-width: 768px) {
  .playcards-images {
    flex-direction: column;
    align-items: center;
  }

  .playcards-images img {
    max-width: 80%;
    margin-bottom: 20px;
  }
}

.playcards-images img:hover {
  /* transform: scale(1.2); */
  z-index: 1;
  transform: translateY(-10px) scale(1.05);
  /* box-shadow: 0 15px 30px rgba(0,0,0,0.2); */
}

.playcards-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.playcards-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.playcards-modal .close {
  background: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.playcards-modal .close:hover {
  transform: rotate(90deg);
  background: #f64f59;
  color: #fff;
}

.playcards-modal h2 {
  margin-top: 0;
}

#about-author {
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  color: white;
  padding: 60px 0;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  padding-left: 20px;
  padding-right: 20px;
}

.author-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.author-image {
  flex: 0 0 40%;
  position: relative;
  width: 100%;
  max-width: 350px;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 10px 30px rgba(0,0,0,0.2);
  margin-left: 5%;
}

.author-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}

.author-info {
  flex: 0 0 40%;
  margin-right: 5%;
  align-items: center;
}

@media (min-width: 768px) {
  .author-content {
    flex-direction: column;
    align-items: center;
  }

  .author-image {
    flex: 0 0 40%;
    margin-bottom: 0;
  }

  .author-info {
    flex: 0 0 35%;
    text-align: left;
  }
}

.author-gap {
  display: none;
}

@media (min-width: 768px) {
  .author-gap {
    display: block;
    flex: 0 0 10%;
  }
}

.author-name {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.author-bio {
  font-size: 1.1rem;
  margin-bottom: 30px;
}

.social-links {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.social-link {
  color: white;
  font-size: 1.5rem;
  transition: transform 0.3s ease;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

.social-link:hover {
  transform: translateY(-5px);
  background-color: rgba(255, 255, 255, 0.3);
}

#contact {
  background-color: white;
}

.contact-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.contact-info,
.contact-form {
  width: 100%;
}

.contact-method {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.contact-icon {
  font-size: 2rem;
  color: var(--primary-color);
  margin-right: 20px;
}

.contact-details h3 {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  position: relative;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-group input:hover,
.form-group textarea:hover {
  border-color: #999;
  cursor: text;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--primary-color);
}

.form-group label {
  position: absolute;
  left: 15px;
  top: 15px;
  color: #888;
  transition: all 0.3s ease;
  pointer-events: none;
}

.form-group input:focus + label,
.form-group input:not(:placeholder-shown) + label,
.form-group textarea:focus + label,
.form-group textarea:not(:placeholder-shown) + label {
  top: -10px;
  left: 10px;
  font-size: 0.8rem;
  color: var(--primary-color);
  background-color: white;
  padding: 0 5px;
}

.submit-btn {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: var(--secondary-color);
}

#testimonials {
  background-color: #fff;
}

.testimonial-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.testimonial-card {
  background-color: var(--background-color);
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.testimonial-content {
  font-style: italic;
  margin-bottom: 20px;
}

.testimonial-author {
  font-weight: 600;
  color: var(--primary-color);
}

.scroll-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--primary-color);
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  text-decoration: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1000;
}

.scroll-top.visible {
  opacity: 1;
}

footer {
  background-color: var(--text-color);
  color: white;
  text-align: center;
  padding: 20px 0;
  margin-top: 60px;
}

@keyframes float {
  0% { transform: translateY(0) rotate(0deg); }
  25% { transform: translateY(-15px) rotate(5deg); }
  50% { transform: translateY(-30px) rotate(-5deg); }
  75% { transform: translateY(-15px) rotate(3deg); }
  100% { transform: translateY(0) rotate(0deg); }
}

.floating-leaves {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
}

.leaf {
  position: absolute;
  width: 30px;
  height: 30px;
  background-image: url('../images/leaf.png');
  background-size: contain;
  opacity: 0.6;
  animation: fall 10s linear infinite;
}

@keyframes fall {
  0% {
    top: -10%;
    transform: translateX(0) rotate(0deg);
  }
  100% {
    top: 110%;
    transform: translateX(100px) rotate(360deg);
  }
}

.leaf:nth-child(2) {
  left: 25%;
  animation-delay: -2s;
  animation-duration: 9s;
}

.leaf:nth-child(3) {
  left: 50%;
  animation-delay: -4s;
  animation-duration: 7s;
}

.leaf:nth-child(4) {
  left: 75%;
  animation-delay: -6s;
  animation-duration: 10s;
}

.leaf:nth-child(5) {
  left: 90%;
  animation-delay: -8s;
  animation-duration: 8s;
}

.leaf:nth-child(2n) {
  left: 10%;
  animation-delay: -2s;
  animation-duration: 12s;
}

.leaf:nth-child(3n) {
  left: 30%;
  animation-delay: -4s;
  animation-duration: 9s;
}

.leaf:nth-child(4n) {
  left: 50%;
  animation-delay: -6s;
  animation-duration: 11s;
}
.leaf:nth-child(5n) {
  left: 70%;
  animation-delay: -8s;
  animation-duration: 13s;
}

.leaf:nth-child(6n) {
  left: 90%;
  animation-delay: -10s;
  animation-duration: 8s;
}

.modal {
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 700px;
  max-height: 85vh;
  overflow-y: auto;
  border-radius: 10px;
  position: relative;
  margin: auto;
  margin-top: 5vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

}

.close {
  position: sticky;
  top: 0;
  margin-left: auto;
  background: none;
  /* border: none; */
  font-size: 1.5rem;
  cursor: pointer;
  padding: 10px;
  z-index: 1002;
  transition: all 0.3s ease;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  /* background: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  transition: all 0.3s ease; */

}

.close:hover,
.close:focus {
  /* color: var(--primary-color);
  text-decoration: none;
  cursor: pointer; */
  transform: rotate(90deg);
  background: #f64f59;
  color: #fff;
}

.modal-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  display: block;
  margin: 0 auto 20px;
}

.modal-description {
  margin-bottom: 20px;
}

.order-button {
  display: inline-block;
  background-color: var(--primary-color);
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.order-button:hover {
  background-color: var(--secondary-color);
}

@media (min-width: 768px) {
  nav {
    flex-direction: row;
    justify-content: space-between;
  }

  .logo {
    margin-bottom: 0;
  }

  .nav-links {
    flex-direction: row;
  }

  .nav-links a {
    margin: 0 10px;
  }

  .author-content {
    flex-direction: row;
    align-items: flex-start;
  }

  .author-image {
    margin-right: 30px;
    margin-bottom: 0;
  }

  .author-info {
    text-align: left;
  }

  .contact-content {
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
  }

  .contact-info,
  .contact-form {
    flex: 1;
  }
}

@media (max-width: 768px) {
  .section {
    padding: 40px 0;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .book-grid,
  .testimonial-grid {
    grid-template-columns: 1fr;
  }
}


